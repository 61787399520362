<template>
  <div class="computer-errors-main">
    <div class="computer-errors-main-header">
        <h1>מערכת תקלות מחשוב</h1>
        <!-- <div v-if=" role>=99" @click="state='ComputerErrorsManage'" class="manage-btn">
            <i class="material-icons">settings</i>
        </div> -->
        <!-- <div v-if="state=='ComputerErrorsManage' && (role>=99)" @click="state='ComputerErrorForm'" class="back-btn">
            <i @click="state='ComputerErrorForm'" class="material-icons">arrow_back</i>
        </div> -->
    </div>
    <div class="computer-errors-main-content">
        <KeepAlive>
            <component
                @open_call="state='ComputerErrorForm'"
                @go_back="state='ComputerErrorHomeScreen'"
                @open_calls_manage="state='ComputerErrorsCallsManager'"
                @submit_new_call="state='ComputerErrorHomeScreen'"
                :is="state"
             />
        </KeepAlive>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, defineAsyncComponent } from '@vue/runtime-core'

import {role} from '../../../Methods/Utils'

export default {
    components:{
        ComputerErrorForm:defineAsyncComponent(()=>import('./components/ComputerErrorForm.vue')),
        // ComputerErrorsManage:defineAsyncComponent(()=>import('./components/ComputerErrorsManage.vue')),
        ComputerErrorsCallsManager:defineAsyncComponent(()=>import('./components/ComputerErrorsCallsManager.vue')),
        ComputerErrorHomeScreen:defineAsyncComponent(()=>import('./components/ComputerErrorHomeScreen.vue')),
    },
    setup(){
        const state = ref('ComputerErrorHomeScreen')

        return{state,role}
    }
}
</script>

<style scoped>
    .computer-errors-main{
        width: 100%;
        height: 100%;
        color: #fff;
        padding:0 5px;
        
    }
    .computer-errors-main-header{
        position: relative;
        width: 100%;
        height: 50px;
        background: var(--secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    .computer-errors-main-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
    }
    .manage-btn,.back-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        width: 30px;
        height: 30px;
        background: var(--warning);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        cursor: pointer;
        user-select: none;
    }
    .back-btn{
        background: var(--danger);
        color: #fff;
    }
</style>